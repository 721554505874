import React, { useEffect, useState, useContext } from 'react'
import { use, useCustomNav } from 'utils/customNavigate'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  Background,
  Button,
  Row,
  Column,
  ComponentWrapper,
  SectionTitle,
  StokrLoader,
} from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'
import fixDecimals from 'utils/fix-decimals'
import { UserConsumer } from 'context/User/UserContext'
import { OrderContext } from 'context/Order/OrderContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { UserContext } from 'context/User/UserContext'
import { SectionTitlePageText } from './select-address'

const BoldText = styled.div`
  font-weight: 900;
  text-transform: uppercase;
  display: initial;
`

const ShowIbanPage = () => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  let { order, storedInvestment: investment } = useContext(OrderContext)
  let { project } = useContext(ProjectContext)
  let { user } = useContext(UserContext)
  const navigator = useCustomNav()

  useEffect(() => {
    if (user.selectedAddress) {
      setShouldRenderPage(true)
    } else {
      navigator.navigate(`/${project.name}`)
    }
  }, [])

  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          { id: 'select-address', handleClick: () => {} },
          { id: 'order-tokens', handleClick: () => {} },
          { id: 'confirm', handleClick: () => {} },
        ],
        doneIndex: 2,
        activeIndex: 3,
      }}
      footerColor="red"
    >
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['STOKR', 'Tokenization', 'Investing']}
      />
      <UserConsumer>
        {({ user }) => (
          <Row main>
            <Column part={8}>
              <Background src={backgroundImage} mobileRatio="16:9" />
            </Column>

            <Column part={8} withBorder>
              <ComponentWrapper>
                <SectionTitle>{SectionTitlePageText(project)}</SectionTitle>
              </ComponentWrapper>

              <ComponentWrapper noPaddingTop>
                <Text>
                  <h3>{user?.currencyName || 'EURO'} Transfer</h3>
                  <p>
                    {`
                    You are required to send ${
                      user.currencySymbol
                    } ${fixDecimals(
                      order.currencyValue,

                      project.euroDecimals,
                    )} in exchange for
                    ${fixDecimals(
                      order.tokenAmount,
                      project.tokenRoundingDecimals,
                    )} ${project.tokenSymbol}.`}
                  </p>

                  <p>
                    <BoldText>
                      IMPORTANT - TO COMPLETE YOUR{' '}
                      {project?.name === 'techforgood'
                        ? 'DONATION'
                        : 'INVESTMENT'}{' '}
                      YOU WILL NEED:
                      <br />- The STOKR reference number listed in the transfer
                      <br /> - A bank account in your name
                    </BoldText>
                  </p>
                  <p>
                    You can follow the progress in the transactions tab of your
                    portfolio as soon as you have completed the transfer and the
                    cash has hit the bank account. Allow 72 business hours at
                    minimum for the transfer to be credited. Transfers are not
                    done on weekends.
                  </p>
                  <p>
                    Send the{' '}
                    <BoldText>
                      {user.currencySymbol}{' '}
                      {`${fixDecimals(
                        order.currencyValue,
                        project.euroDecimals,
                      )}`}
                    </BoldText>{' '}
                    to:{' '}
                  </p>
                  <BankDetails
                    {...(['chf', 'usd'].includes(investment.currencyType)
                      ? {
                          accountHolder:
                            investment.mangopayBankAccount.OwnerName,
                          iban: investment.mangopayBankAccount.IBAN,
                          bic: investment.mangopayBankAccount.BIC,
                          reference: investment.mangopayReference,
                          address: formatAddress(
                            investment.mangopayBankAccount.OwnerAddress
                              .AddressLine1,
                            investment.mangopayBankAccount.OwnerAddress
                              .AddressLine2,
                            investment.mangopayBankAccount.OwnerAddress.City,
                            investment.mangopayBankAccount.OwnerAddress
                              .PostalCode,
                            investment.mangopayBankAccount.OwnerAddress.Country,
                          ),
                        }
                      : {
                          accountHolder: project.company.accountHolder,
                          iban: project.company.iban,
                          bic: project.company.bic,
                          reference: order.investmentId,
                          address:
                            '2 avenue Amélie, L-1125 Luxembourg, LUXEMBOURG',
                        })}
                  />
                </Text>
              </ComponentWrapper>

              <ComponentWrapper
                noPaddingTop
                style={{ marginBottom: '5rem', marginTop: '5rem' }}
              >
                <Button
                  minWidth="240px"
                  onClick={() =>
                    window.location.replace(
                      'https://dashboard.stokr-staging.de',
                    )
                  }
                >
                  Your dashboard
                </Button>
              </ComponentWrapper>
            </Column>
          </Row>
        )}
      </UserConsumer>
    </Layout>
  ) : (
    <StokrLoader />
  )
}

const formatAddress = (
  addressLine1,
  addressLine2,
  city,
  postalCode,
  country,
) => {
  const parts = [addressLine1, addressLine2, `${postalCode} ${city}`, country]
  return parts.filter(Boolean).join(', ')
}

const BankDetails = ({ accountHolder, iban, bic, reference, address }) => (
  <>
    <p>
      <BoldText>Account holder</BoldText>: {accountHolder}
    </p>
    <p>
      <BoldText>IBAN</BoldText>: {iban}
    </p>
    <p>
      <BoldText>BIC</BoldText>: {bic}
    </p>
    <p>
      <BoldText>Reference</BoldText>: {reference}
    </p>
    <p>
      <BoldText>Bank Address</BoldText>: {address}
    </p>
  </>
)

export default ShowIbanPage
